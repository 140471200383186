import { Container, BoxArticles } from "./styles";
import { BlogArticle, Button } from "../../../components";
import { useEffect, useState } from "react";

const Block11: React.FC = () => {
  const [articles, setArticles] = useState([]);
  const [categories, setCategories] = useState([]);

  const findCategory = (id: number) => {
    if (!categories) return;
    const found = categories?.find(x => x.id === id[0]);

    if (found) return found.name;
    return "";
  };

  const seachCategories = (arrayCategories: Number[]) => {
    fetch(`/api/blogCategory/${arrayCategories.join()}`)
      .then(response => response.json())
      .then(categories => {
        setCategories(categories);
      })
      .catch(error => console.warn(error));
  };

  useEffect(() => {
    let arrayCategories = [];

    fetch("/api/blog")
      .then(response => response.json())
      .then(articles => {
        setArticles(articles);
        articles.forEach(article => {
          arrayCategories.push(article.categories[0]);
        });
        seachCategories(arrayCategories);
      })
      .catch(error => console.warn(error));
  }, []);

  return (
    <Container>
      <h1>
        Fique por dentro das<span>&nbsp;[Novidades]</span>
      </h1>
      <BoxArticles>
        {articles.map((article, key) => (
          <BlogArticle
            key={key}
            link={article.link}
            date={article.date}
            text={article.title.rendered}
            img={article.jetpack_featured_media_url}
            category={findCategory(article.categories)}
          />
        ))}
      </BoxArticles>
      <div className="btn-read-more">
        <Button variant="secondary" href="https://blog.2ndmarket.com.br/">
          Leia mais
        </Button>
      </div>
    </Container>
  );
};

export default Block11;
