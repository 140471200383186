import { useRef } from "react";
import {
	Container,
	BoxActions,
	BoxCards,
	ButtonNav,
	BoxNav,
	Content,
} from "./styles";

import ProductUrls from "@2ndmarket/components/src/helper/ProductUrls";
import {
	BlockPattern,
	Button,
	CardStep,
} from "../../../components";
import { Icon } from "@2ndmarket/components";

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import SwiperCore, { Navigation } from "swiper";
SwiperCore.use([Navigation]);

const Block08: React.FC = () => {
	const prevRef = useRef<HTMLButtonElement>(null);
	const nextRef = useRef<HTMLButtonElement>(null);

	const onBeforeInit = (Swiper: SwiperCore): void => {
		if (typeof Swiper.params.navigation !== "boolean") {
			const navigation = Swiper.params.navigation;
			navigation.prevEl = prevRef.current;
			navigation.nextEl = nextRef.current;
		}
	};

	return (
		<Content>
			<img
				alt=""
				width={9}
				height={9}
				src="/four-squares.svg"
				className="decoration one"
			/>
			<BlockPattern
				cartola="Como funciona: Cripto Now"
				title="Em poucos passos você aproveita todos os recursos em qualquer lugar
        do mundo."
				maxWidth={64}
			/>
			<Container>
				<BoxCards>
					<CardStep
						icon={<Icon name="wallet" size={20} />}
						title="Abra sua wallet"
						text="Abra sua carteira cripto ou digital para comprar ou transferir suas criptomoedas de uma wallet externa. Nós realizamos a conversão e custódia para você."
					/>
					<CardStep
						icon={<Icon name="coin" size={20} />}
						title="Crie seu portfólio"
						text="Vericamos seus dados de foma rápida e após 
            a confirmação enviada por e-mail, você já pode
            construir seu portfólio com as melhores ciprtos
            do mercado."
					/>
					<CardStep
						icon={<Icon name="send" size={20} />}
						title="Use como quiser"
						text="Converta automaticamente suas criptomoedas em FIAT na sua wallet e utilize como quiser, pague, transfira, saque ou gaste diretamente em seu cartão de débito Bankai. "
					/>
				</BoxCards>
				<Swiper
					slidesPerView={1}
					slidesPerGroup={1}
					spaceBetween={20}
					onBeforeInit={onBeforeInit}
				>
					<SwiperSlide>
						<CardStep
							icon={<Icon name="wallet" size={20} />}
							title="Abra sua wallet"
							text="Abra sua carteira cripto ou digital para comprar ou transferir suas criptomoedas de uma wallet externa. Nós realizamos a conversão e custódia para você."
						/>
					</SwiperSlide>
					<SwiperSlide>
						<CardStep
							icon={<Icon name="coin" size={20} />}
							title="Crie seu portfólio"
							text="Vericamos seus dados de foma rápida e após 
            a confirmação enviada por e-mail, você já pode
            construir seu portfólio com as melhores ciprtos
            do mercado."
						/>
					</SwiperSlide>
					<SwiperSlide>
						<CardStep
							icon={<Icon name="send" size={20} />}
							title="Use como quiser"
							text="Converta automaticamente suas criptomoedas em FIAT na sua wallet e utilize como quiser, pague, transfira, saque ou gaste diretamente em seu cartão de débito Bankai. "
						/>
					</SwiperSlide>
					<BoxNav>
						<ButtonNav ref={prevRef} aria-label="Anterior">
							<Icon
								name="arrow-alternative"
								rotate="180deg"
								size={16}
							/>
						</ButtonNav>
						<ButtonNav ref={nextRef} aria-label="Próximo">
							<Icon name="arrow-alternative" size={16} />
						</ButtonNav>
					</BoxNav>
				</Swiper>
				<BoxActions>
					<Button href={ProductUrls.AUTH_REGISTER}>
						Comece agora
					</Button>
					<Button
						variant="secondary"
						href="/Pitch-BNKCom.pdf"
						download="Pitch Bankai 2023"
					>
						Baixe o Pitch
					</Button>
				</BoxActions>
			</Container>
		</Content>
	);
};

export default Block08;
