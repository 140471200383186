import styled from "styled-components";
import { theme } from "styled-tools";

export const Container = styled.div`
	flex-direction: column;
	max-width: 100%;

	.swiper {
		width: 100%;
		padding: 70px 4vw 0;

		@media only screen and (min-width: ${theme("breakpoints.extralarge")}) {
			display: none;
		}
	}
`;

export const BoxActions = styled.div`
	display: flex;
	width: 100%;
	justify-content: center;
	gap: 29px;
	padding: 30px 0;

	@media only screen and (max-width: ${theme("breakpoints.extralarge")}) {
		padding: 60px 4vw 30px;
		display: flex;
		gap: 15px;
		justify-content: start;

		a,
		button {
			width: 100%;
		}
	}
`;

export const BoxCards = styled.div`
	padding: 75px 6vw;
	display: flex;
	gap: 2vw;

	@media only screen and (max-width: ${theme("breakpoints.extralarge")}) {
		display: none;
	}
`;

export const ButtonNav = styled.button`
	width: 40px;
	height: 40px;
	background: rgba(229, 233, 238, 0.6);
	border-radius: 2px;
  transition: background-color .25s;

	i {
		color: ${theme("colors.primary.main")};
	}

	:disabled {
		i {
			color: ${theme("colors.light.gray.3")};
		}
	}

  :hover {
    background-color: ${theme("colors.primary.main")};

    i {
      color: ${theme("colors.white")};
    }
  }
`;

export const BoxNav = styled.div`
	display: flex;
	gap: 5px;
	margin-top: 20px;
`;

export const Content = styled.div`
	position: relative;

	.decoration {
		position: absolute;

		&.one {
			top: 244px;
			right: 269px;
		}

		@media only screen and (max-width: ${theme("breakpoints.extralarge")}) {
			&.one {
				top: 264px;
				right: 167px;
			}
		}
	}
`;
