import { Icon } from "@2ndmarket/components";
import { BlockPattern } from "../../../components";
import { Additional } from "../../../components/BlockPattern/styles";
import { CircleItem, BoxItem, Container } from "./styles";

const Block03: React.FC = () => {
	const items = [
		{
			label: "Comprar",
			icon: <Icon name="bag" />,
		},
		{
			label: "Sacar",
			icon: <Icon name="card" />,
		},
		{
			label: "Enviar",
			icon: <Icon name="send" />,
		},
		{
			label: "Receber",
			icon: <Icon name="receive" />,
		},
		{
			label: "Converter",
			icon: <Icon name="change" />,
		},
		{
			label: "Gastar",
			icon: <Icon name="store" />,
		},
	];

	return (
		<Container>
			<img
				alt=""
				width={9}
				height={9}
				src="/four-squares.svg"
				className="decoration one"
			/>
			<img
				alt=""
				width={87}
				src="/square-gray.svg"
				className="decoration two"
			/>
			<BlockPattern
				marginX={22}
				cartola="Traga a nova criptoeconomia para seu dia-a-dia"
				title="Gerencie e visualize seu portfólio diversificado de ativos digitais de forma simplificada."
				paragraph="Finanças descentralizadas e serviços de custódia - armazenamento das chaves criptográficas que controlam ativos digitais, seja para Criptomoeda, Blockchain ou Dlt e toda documentação necessária para garantir a eficiência da transação."
			>
				<Additional
					justifyContent="space-between"
					wrap="wrap"
					gapMobile={30}
					marginXMobile={8}
				>
					{items.map((item, key) => (
						<BoxItem key={key}>
							<CircleItem>{item.icon}</CircleItem>
							<span>{item.label}</span>
						</BoxItem>
					))}
				</Additional>
			</BlockPattern>
		</Container>
	);
};

export default Block03;
