import styled from "styled-components";
import { theme } from "styled-tools";

export const Container = styled.div`
	padding: 75px 6vw;

	display: grid;
	flex-direction: column;
	justify-content: center;

	h1 {
		display: flex;
		align-items: start;
		font-size: 18px;
		font-weight: 600;
		line-height: 22px;
		margin-bottom: 49px;
		color: ${theme("colors.light.gray.1")};

		span {
			color: ${theme("colors.primary.main")};
		}
	}

	.btn-read-more {
		display: flex;
		justify-content: center;

		a {
			text-align: center;
			width: 220px;
		}
	}

	@media only screen and (max-width: ${theme(
			"breakpoints.extralarge"
		)}) {
		padding: 30px 4vw;
	}
`;

export const BoxArticles = styled.div`
	margin-bottom: 60px;
	display: flex;
	width: 100%;
	gap: 5vw;

	a {
		text-align: left;
		width: 33.3%;
	}

	@media only screen and (max-width: ${theme(
			"breakpoints.extralarge"
		)}) {
		flex-direction: column;
		gap: 28px;

		a {
			width: 100%;
		}
	}
`;
