import styled from "styled-components";
import { theme } from "styled-tools";

export const CircleItem = styled.div`
	width: 60px;
	height: 60px;
	border-radius: 50%;
	background: rgba(229, 233, 238, 0.6);
	display: flex;
	align-items: center;
	justify-content: center;

	i {
		color: ${theme("colors.primary.main")};
		font-size: 20px;
	}
`;

export const BoxItem = styled.div`
	display: flex;
	flex-direction: column;
	gap: 11px;
	align-items: center;

	span {
		font-weight: 400;
		font-size: 16px;
		line-height: 22px;
		color: ${theme("colors.light.gray.2")};
	}
`;

export const Container = styled.div`
	position: relative;

	.decoration {
		position: absolute;

		&.one {
			top: 110px;
			right: 240px;
		}

		&.two {
			top: 130px;
			right: 86px;
		}

		@media only screen and (max-width: ${theme(
				"breakpoints.extralarge"
			)}) {
			display: none;
		}
	}

	article {
		margin-top: 50px;

		p {
			padding: 0 16% 0 0;
		}
	}
`;
