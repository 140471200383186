import { BlockPattern, Button } from "../../../components";
import { Additional } from "../../../components/BlockPattern/styles";
import { Cartola } from "../../../components/Text";
import { Container } from "./styles";
import ProductUrls from "@2ndmarket/components/src/helper/ProductUrls";

const Block04: React.FC = () => {
	return (
		<Container>
			<img
				alt=""
				width={9}
				height={9}
				src="/four-squares.svg"
				className="decoration one"
			/>
			<img
				alt=""
				width={3}
				height={3}
				src="/one-square-gray.svg"
				className="decoration two"
			/>
			<BlockPattern
				cartola="Controle suas transações."
				subTitle="Controle total de todas
        as movimentações das
        duas carteiras."
				paragraph="Além de obter o extrato da sua carteira digital com PIX, TED e boleto, em reais e em cripto, é possível visualizar suas transações em cripto na rede blockchain. Tanto de compra, quanto de venda."
				additional={
					<img alt="" width={605} src="/img/home-block04.png" />
				}
				marginX={12}
				mobileReverse
			>
				<Additional>
					<Cartola>
						Acrescente tecnologia aos seus pagamentos
					</Cartola>
				</Additional>
				<Button href={ProductUrls.AUTH_REGISTER}>
					Comece agora
				</Button>
			</BlockPattern>
		</Container>
	);
};

export default Block04;
