import { BlockPattern, Button } from "../../../components";
import { Additional, Text } from "../../../components/BlockPattern/styles";
import { Cartola, Paragraph } from "../../../components/Text";
import { Container } from "./styles";

const Block07: React.FC = () => {
  return (
    <Container>
      <img
        alt=""
        width={9}
        height={9}
        src="/four-squares.svg"
        className="decoration one"
      />
      <img
        alt=""
        width={3}
        height={3}
        src="/one-square.svg"
        className="decoration two"
      />
      <BlockPattern
        cartola="Bank.ai Card"
        subTitle="Você não precisa de
        muito, apenas de um
        cartão Bank.ai!"
        paragraph="Traga cripto para o seu dia-a-dia. Utilize seu cartão para realizar compras e saques no Brasil e no exterior. Com o Bankai.Card você pode utilizar seus ativos digitais sem necessidade de conversão."
        additional={<img alt="" width={605} src="/img/home-block07.png" />}
        marginX={12}
        mobileReverse>
        <Additional column>
          <Text className="list-block">
            <Cartola>Zero taxa de emissão. Zero taxa de manutenção.</Cartola>
            <Paragraph>
              Expanda sua lista de usuários, transacione sem barreiras externa
              com pessoas de todo mundo dentro de uma infraestrutura de
              ponta.Tenha em mãos o poder de compra em Bitcoin. Bank.aiCard é um
              cartão pré-pago, você pode escolher utilziar para pagamento em
              cripto ou não.
            </Paragraph>
          </Text>
        </Additional>
        <Button href="bankai-card" self>
          Saiba mais
        </Button>
      </BlockPattern>
    </Container>
  );
};

export default Block07;
