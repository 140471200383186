import styled from "styled-components";
import { theme } from "styled-tools";

export const Container = styled.div`
	position: relative;

	.decoration {
		position: absolute;

		&.one {
			top: 104px;
			left: 45vw;
		}

		@media only screen and (max-width: 1440px) {
			&.one {
				left: 39vw;
			}
		}

		&.two {
			bottom: 108px;
			left: 445px;
		}

		&.three {
			right: 43px;
			bottom: 206px;
		}

		@media only screen and (max-width: ${theme(
				"breakpoints.extralarge"
			)}) {
			display: none;
		}
	}
`;
