import { Header, BankaiSEO, Footer } from "../components";
import {
  Block01,
  Block02,
  Block03,
  Block04,
  Block05,
  Block06,
  Block07,
  Block08,
  Block09,
  Block10,
  Block11,
} from "../blocks/home";

export default function Home() {
  return (
    <>
      <BankaiSEO
        title="BANKAI - Plataforma Digital Asset Bank"
        description="Desbloqueie a liquidez! Obtenha sua wallet gratuita para ativos digitais e criptomoedas. A BANKAI é sua porta de entrada para digital finance - a criptografia, custódia e gestão de digital assets."
      />
      <Header />
      <Block01 />
      <Block02 />
      <Block03 />
      <Block04 />
      <Block05 />
      <Block06 />
      <Block07 />
      <Block08 />
      <Block09 />
      <Block10 />
      <Block11 />
      <Footer />
    </>
  );
}
