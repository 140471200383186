import styled from "styled-components";
import { theme } from "styled-tools";

export const Container = styled.div`
	position: relative;

	.decoration {
		position: absolute;

		&.one {
			top: 74px;
			left: 39px;
		}

		&.two {
			right: 49px;
			bottom: 206px;
		}

		@media only screen and (max-width: ${theme(
				"breakpoints.extralarge"
			)}) {
			display: none;
		}
	}
`;
