import { BlockPattern, Button } from "../../../components";
import { Additional } from "../../../components/BlockPattern/styles";
import { Cartola } from "../../../components/Text";
import { Container } from "./styles";
import ProductUrls from "@2ndmarket/components/src/helper/ProductUrls";

const Block09: React.FC = () => {
	return (
		<Container>
			<img
				alt=""
				width={9}
				height={9}
				src="/four-squares.svg"
				className="decoration one"
			/>
			<img
				alt=""
				width={3}
				height={3}
				src="/one-square-gray.svg"
				className="decoration two"
			/>
			<BlockPattern
				marginX={12}
				reverse
				cartola="Consolidação ou representação de ativos"
				subTitle="Represente ativos como tokens digitais."
				paragraph="Oferecemos serviços financeiros ligados a rede blockchain por meio de representação de ativos digitais  consolidados para visualização na Bank.ai Wallet"
				additional={
					<img alt="" width={605} src="/img/home-block09.png" />
				}
				mobileReverse
			>
				<Additional>
					<Cartola>Alto padrão em Digital Asset Bank</Cartola>
				</Additional>
				<Button href={ProductUrls.AUTH_REGISTER}>
					Comece agora
				</Button>
			</BlockPattern>
		</Container>
	);
};

export default Block09;
