import styled from "styled-components";
import { theme } from "styled-tools";

export const Container = styled.div`
	position: relative;

	.block-10 {
		display: flex;
		flex-direction: row;
		justify-content: space-between;

		.decoration {
			&.one {
				display: none;
			}

			&.two {
				top: 244px;
				right: 269px;
				position: absolute;
			}

			&.three {
				width: 160px;
				margin-top: 40px;
			}

			@media only screen and (max-width: ${theme(
					"breakpoints.extralarge"
				)}) {
				position: absolute;

				&.one {
					display: none;
				}

				&.two {
					top: 355px;
					right: 167px;
				}

				&.three {
					display: inherit;
					top: 276px;
					right: 0;

					width: 99px;
					height: 139px;
				}
			}
		}
	}
`;
