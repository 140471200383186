import { BlockPattern, Button } from "../../../components";
import {
	Additional,
	Text,
} from "../../../components/BlockPattern/styles";
import { Cartola, Paragraph } from "../../../components/Text";
import { Container } from "./styles";
import ProductUrls from "@2ndmarket/components/src/helper/ProductUrls";

const Block10: React.FC = () => {
	return (
		<Container>
			<div className="block-10">
				<BlockPattern
					cartola="B2B"
					title="Utilize a API da Bank.ai e receba em criptomoedas aumentando receita, venda para o mundo todo."
					maxWidth={67}
				/>
				<img
					alt=""
					width={9}
					height={9}
					src="/four-squares.svg"
					className="decoration two"
				/>
				<img
					alt=""
					width={160}
					height={297}
					src="/broken-square.svg"
					className="decoration three"
				/>
			</div>
			<BlockPattern
				additional={
					<img alt="" width={605} src="/img/home-block10.png" />
				}
				marginX={12}
				mobileReverse
				mtMobile={165}
			>
				<Additional column gap={28}>
					<Text>
						<Cartola>Como funciona</Cartola>
						<Paragraph>
							1. Receba em criptomoeda em sua wallet. <br />
							2. Efetue a conversão automática de sua criptomoeda
							pela Bankai.
							<br />
							3. Solicite um saque em FIAT para o seu banco.
						</Paragraph>
					</Text>
					<Text>
						<Cartola>Wallet com QR-Code para PJ</Cartola>
						<Paragraph>
							Deixe o QR-code com o endereço público de sua
							Wallet Bankai em seu e-commerce ou em seu
							estabelecimento físico e receba em Criptomoedas.
						</Paragraph>
					</Text>
					<Button href={ProductUrls.AUTH_REGISTER}>
						Comece agora
					</Button>
				</Additional>
			</BlockPattern>
		</Container>
	);
};

export default Block10;
