import { useEffect } from "react";
import {
	Additional,
	Container,
	Text,
} from "../../../components/BlockPattern/styles";
import { CryptoConvert } from "../../../components";
import {
	Cartola,
	Paragraph,
	Title,
} from "../../../components/Text";

const Block02: React.FC = () => {
	useEffect(() => {
		if (typeof window !== "undefined") {
			var element = document.getElementById("crypto");
			var content = [
				"Bitcoin...",
				"Ethereum...",
				"Cardano...",
				"Ripple...",
			];
			var part = 0;
			var partIndex = 0;
			var interval = setInterval(Type, 100);
		}

		function Type() {
			var text = content[part].substring(0, partIndex + 1);
			element!.innerHTML = text;
			partIndex++;

			if (text === content[part]) {
				clearInterval(interval);
				setTimeout(function () {
					interval = setInterval(Delete, 50);
				}, 1000);
			}
		}

		function Delete() {
			var text = content[part].substring(0, partIndex - 1);
			element!.innerHTML = text;
			partIndex--;

			if (text === "") {
				clearInterval(interval);

				if (part == content.length - 1) part = 0;
				else part++;

				partIndex = 0;

				setTimeout(function () {
					interval = setInterval(Type, 100);
				}, 200);
			}
			crypto;
		}
	}, []);

	return (
		<Container marginX={12}>
			<img
				alt=""
				width={3}
				height={3}
				src="/one-square.svg"
				className="decoration one"
			/>
			<Text>
				<Cartola>Conversão cripto</Cartola>
				<Title>
					Quanto vale um <br />
					<p id="crypto" />
				</Title>
				<Paragraph>
					Plataforma disponível 24 horas por dia para comprar e
					vender criptoativos a qualquer momento e de forma. Sem
					taxas. Sem restrições.
				</Paragraph>
				<Additional>
					<Cartola>Simule ao lado!</Cartola>
				</Additional>
			</Text>
			<CryptoConvert />
		</Container>
	);
};

export default Block02;
