import { Icon } from "@2ndmarket/components";
import ProductUrls from "@2ndmarket/components/src/helper/ProductUrls";

import { BlockPattern, Button } from "../../../components";
import { Cartola, Paragraph } from "../../../components/Text";

import {
  ContentIcon,
  Additional,
  Text,
} from "../../../components/BlockPattern/styles";

import { Container } from "./styles";

const Block01: React.FC = () => {
  return (
    <Container>
      <img
        alt=""
        width={9}
        height={9}
        src="/four-squares.svg"
        className="decoration one"
      />
      <img
        alt=""
        width={3}
        height={3}
        src="/one-square.svg"
        className="decoration two"
      />
      <img
        alt=""
        width={3}
        height={3}
        src="/one-square.svg"
        className="decoration three"
      />
      <BlockPattern
        marginX={12}
        mobileReverse
        maxWidth={389}
        cartola="Obtenha mais dos seus ativos."
        title="O seu banco de ativos digitais e muito mais!"
        additional={<img alt="" width={605} src="/img/home-block01.jpg" />}
        paragraph="A Bank.ai é sua porta de entrada para digital finance. Obtenha agora sua wallet gratuita para ativos digitais e criptomoedas. Faça parte da nova economia!">
        <Additional>
          <ContentIcon>
            <Icon name="2nd" />
          </ContentIcon>
          <Text>
            <Cartola>Bkai Coin</Cartola>
            <Paragraph>
              A Bank.ai Coin é o passe essencial para todo o ecossistema. Com
              ela, você ainda consegue rentabilizar e sacar!
            </Paragraph>
          </Text>
        </Additional>
        <Button href={ProductUrls.AUTH_REGISTER}>Comece agora</Button>
      </BlockPattern>
    </Container>
  );
};

export default Block01;
