import styled from "styled-components";
import { theme } from "styled-tools";

interface Props {
	crypto?: string;
	right?: boolean;
}

export const Desktop = styled.div`
	display: flex;
	flex-direction: column;

	@media only screen and (max-width: ${theme(
			"breakpoints.extralarge"
		)}) {
		display: none;
	}
`;

export const Container = styled.div`
	position: relative;

	.block-05 {
		display: flex;
		flex-direction: row;
		justify-content: space-between;

		.decoration {
			&.one {
				display: none;
			}

			&.two {
				top: 244px;
				right: 269px;
				position: absolute;
			}

			&.three {
				margin-top: 40px;
				width: 160px;
			}

			@media only screen and (max-width: ${theme(
					"breakpoints.extralarge"
				)}) {
				position: absolute;

				&.one {
					display: none;
				}

				&.two {
					top: 355px;
					right: 167px;
				}

				&.three {
					display: inherit;
					top: 276px;
					right: 0;

					width: 99px;
					height: 139px;
				}
			}
		}

		@media only screen and (max-width: ${theme(
				"breakpoints.extralarge"
			)}) {
			flex-direction: column;
			gap: 40px;
		}
	}
`;

export const Table = styled.table`
	width: 800px;
	margin: 75px auto 128px;
	border-collapse: collapse;

	th {
		font-weight: 600;
		font-size: 16px;
		line-height: 20px;
		color: ${theme("colors.light.gray.1")};
		text-align: start;
		padding-bottom: 17px;
	}

	td {
		border-bottom: 1px solid ${theme("colors.light.gray.4")};
		padding: 20px 0;
		font-weight: 400;
		font-size: 16px;
		line-height: 22px;
		color: ${theme("colors.light.gray.2")};

		&.icon {
			display: flex;
			align-items: center;
			gap: 11px;
		}

		&.actions {
			text-align: end;
		}

		&.up {
			font-weight: 500;
			color: ${theme("colors.success.main")};
		}

		&.down {
			font-weight: 500;
			color: ${theme("colors.error.main")};
		}

		img {
			margin-right: 5px;
		}
	}

	@media only screen and (max-width: 1200px) {
		display: none;
	}
`;

export const BoxIcon = styled.div<Props>`
	width: 46px;
	height: 46px;
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: ${(props) =>
		props.crypto &&
		theme(`colors.crypto.${props.crypto.toLowerCase()}`)};
`;

export const Mobile = styled.div`
	display: flex;
	flex-direction: column;

	@media only screen and (min-width: ${theme(
			"breakpoints.extralarge"
		)}) {
		display: none;
	}
`;

export const TableMobile = styled.div`
	display: flex;
	flex-direction: column;
	margin: 170px 4vw 0;

	@media only screen and (min-width: 1200px) {
		display: none;
	}
`;

export const Row = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 22px 0;
	border-bottom: 1px solid ${theme("colors.light.gray.4")};

	> div {
		display: flex;
		align-items: center;
	}

	button {
		width: 46px;
		height: 46px;
		background: ${theme("colors.light.gray.4")};
		border-radius: 2px;
		margin-left: 24px;

		:disabled {
			> i {
				color: ${theme("colors.light.gray.3")};
			}
		}

		> i {
			color: ${theme("colors.primary.main")};
			font-size: 18px;
		}
	}
`;

export const Infos = styled.div<Props>`
	display: flex;
	flex-direction: column;
	margin-left: 11px;
	text-align: ${(props) => props.right && "right"};

	h1 {
		font-weight: 400;
		font-size: 16px;
		line-height: 22px;
		color: ${theme("colors.dark.gray.4")};
	}

	h2 {
		font-style: normal;
		font-weight: 500;
		font-size: 14px;
		line-height: 20px;
		color: ${theme("colors.light.gray.3")};
	}

	p {
		font-weight: 500;
		font-size: 14px;
		line-height: 20px;
		color: ${theme("colors.success.main")};

		&.down {
			color: ${theme("colors.error.main")};
		}
	}
`;
