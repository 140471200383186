import { useEffect, useState } from "react";
import { Icon, themes } from "@2ndmarket/components";
import { formatBRL } from "@2ndmarket/components/src/helper/formatters/fiatFormat";

import { BlockPattern, Button } from "../../../components";

import {
	Row,
	Infos,
	Table,
	Mobile,
	Desktop,
	BoxIcon,
	Container,
	TableMobile,
} from "./styles";

const Block05: React.FC = () => {
	const [cryptos, setCryptos] = useState([]);

	useEffect(() => {
		fetch("/api/cryptos")
			.then((response) => response.json())
			.then((responseData) => {
				setCryptos(responseData);
			})
			.catch((error) => console.warn(error));
	}, []);

	return (
		<Container>
			<Desktop>
				<div className="block-05">
					<BlockPattern
						maxWidth={72}
						cartola="As principais criptos na palma da sua mão"
						title="Investimento ou usabilidade? Adicione as criptomoedas mais negociadas a sua wallet e utilize como desejar"
					/>
					<img
						alt=""
						width={9}
						height={9}
						src="/four-squares.svg"
						className="decoration two"
					/>
					<img
						alt=""
						width={160}
						height={297}
						src="/broken-square.svg"
						className="decoration three"
					/>
				</div>
				<Table>
					<thead>
						<tr>
							<th>Ativo</th>
							<th>Ticker</th>
							<th>Valor</th>
							<th>Variab. 24h</th>
							<th />
						</tr>
					</thead>
					<tbody>
						{cryptos?.map((crypto, key) => (
							<tr key={key}>
								<td className="icon">
									<BoxIcon crypto={crypto.name}>
										<Icon
											size={20}
											name={
												crypto.name === "Bkaicoin"
													? "2nd"
													: crypto.name.toLowerCase()
											}
											color={themes.bankai.colors.white}
										/>
									</BoxIcon>
									{crypto.name}
								</td>
								<td>{crypto.abbreviation.toUpperCase()}</td>
								<td>{formatBRL(crypto.buy * 100)} BRL</td>
								<td
									className={
										crypto.variation < 0 ? "down" : "up"
									}
								>
									<img
										alt=""
										src={
											crypto.variation < 0
												? "/icons/down-icon.svg"
												: "/icons/up-icon.svg"
										}
									/>
									{crypto.variation < 0
										? `${Number(crypto.variation)
												.toFixed(2)
												.replace(".", ",")}%`
										: `+${Number(crypto.variation)
												.toFixed(2)
												.replace(".", ",")}%`}
								</td>
								<td className="actions">
									<Button
										width="115px"
										variant="secondary"
										href={`${crypto.path}`}
										disabled={!crypto.active}
									>
										Negociar
									</Button>
								</td>
							</tr>
						))}
					</tbody>
				</Table>
			</Desktop>
			<Mobile>
				<div className="block-05">
					<BlockPattern
						maxWidth={72}
						cartola="As principais criptos na palma da sua mão"
						title="Utilize a Bkai Coin e as demais criptos para adicionar saldo, converter, gastar e negociar como quiser."
					/>
					<img
						alt=""
						width={9}
						height={9}
						src="/four-squares.svg"
						className="decoration two"
					/>
					<img
						alt=""
						width={160}
						height={297}
						src="/broken-square.svg"
						className="decoration three"
					/>
				</div>
				<TableMobile>
					{cryptos?.map((crypto, key) => (
						<Row key={key}>
							<div>
								<BoxIcon crypto={crypto.name}>
									<Icon
										size={20}
										name={
											crypto.name === "Bkaicoin"
												? "2nd"
												: crypto.name.toLowerCase()
										}
										color={themes.bankai.colors.white}
									/>
								</BoxIcon>
								<Infos>
									<h1>{crypto.name}</h1>
									<h2>{crypto.abbreviation.toUpperCase()}</h2>
								</Infos>
							</div>
							<div className="cotation">
								<Infos right>
									<h1>{formatBRL(crypto.buy)} BRL</h1>
									<p
										className={
											crypto.variation < 0 ? "down" : "up"
										}
									>
										<img
											alt=""
											src={
												crypto.variation < 0
													? "/icons/down-icon.svg"
													: "/icons/up-icon.svg"
											}
										/>
										{crypto.variation < 0
											? `${Number(crypto.variation)
													.toFixed(2)
													.replace(".", ",")}%`
											: `+${Number(crypto.variation)
													.toFixed(2)
													.replace(".", ",")}%`}
									</p>
								</Infos>
								<button disabled={!crypto.active}>
									<Icon name="bag" />
								</button>
							</div>
						</Row>
					))}
				</TableMobile>
			</Mobile>
		</Container>
	);
};

export default Block05;
