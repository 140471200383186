import { BlockPattern, Button } from "../../../components";
import {
	Additional,
	Text,
} from "../../../components/BlockPattern/styles";
import { Cartola, Paragraph } from "../../../components/Text";
import { Container } from "./styles";
import ProductUrls from "@2ndmarket/components/src/helper/ProductUrls";

const Block06: React.FC = () => {
	return (
		<Container>
			<img
				alt=""
				width={9}
				height={9}
				src="/four-squares.svg"
				className="decoration one"
			/>
			<img
				alt=""
				width={3}
				height={3}
				src="/one-square.svg"
				className="decoration two"
			/>
			<BlockPattern
				cartola="Vantagens em cripto"
				subTitle="Negocie com rapidez,
      custodie com mais
      segurança."
				paragraph="Gastar em criptomoedas ou reais está a um clique, escolha o que preferir."
				additional={
					<img alt="" width={605} src="/img/home-block06.png" />
				}
				reverse
				mobileReverse
				marginX={12}
			>
				<Additional column>
					<Text className="list-block">
						<Cartola>Alcance Global</Cartola>
						<Paragraph>
							Expanda sua lista de usuários, transacione sem
							barreiras externa com pessoas de todo mundo dentro
							de uma infraestrutura de ponta.
						</Paragraph>
					</Text>
					<Text className="list-block">
						<Cartola>Mais próximo de você</Cartola>
						<Paragraph>
							Nossa prioridade é o acompanhamento em todos os
							processos para te ajudar a utilizar criptomoedas em
							seu dia-a-dia de maneira fácil e rápida.
						</Paragraph>
					</Text>
					<Text className="list-block">
						<Cartola>
							Segurança nível militar único na América Latina
						</Cartola>
						<Paragraph>
							Armazenamento off-line 100% do tempo, eliminando os
							vetores tradicionais de ataques cibernéticos.
						</Paragraph>
					</Text>
				</Additional>
				<Button href={ProductUrls.AUTH_REGISTER}>
					Comece agora
				</Button>
			</BlockPattern>
		</Container>
	);
};

export default Block06;
